<template>
  <div
    class="d-flex justify-center align-center my-5"
    style="width: 100%;"
  >
    <img src="@/assets/images/main/svg/icon_error.svg">
    <span class="ml-2">{{ title }}</span>
  </div>
</template>

<script>
    export default {
        name: "NoItems",
        props: {
            title: {
                type: String,
                default: "No Items"
            },
        },

    }
</script>

<style scoped>

</style>